import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!username || !password) {
      setError('Por favor, preencha todos os campos');
      return;
    }

    try {
      const response = await axios.post(
        'http://betolopes.com.br/web/authenticate.php',
        { username, password },
        { timeout: 20000 }
      );

      const data = response.data;

      if (data.success) {
        console.log('Dados do usuário:', data.user);
        console.log('Compromissos:', data.compromissos);

        localStorage.setItem('user', JSON.stringify(data.user));
        localStorage.setItem('compromissos', JSON.stringify(data.compromissos));

        navigate('/dashboard', { state: { user: data.user, compromissos: data.compromissos } });
      } else {
        setError(data.message);
      }
    } catch (error) {
      console.error('Erro ao autenticar:', error);
      setError('Ocorreu um erro ao conectar-se ao servidor.');
    }
  };

  return (
    <>
      

      <div
        className="login-section"
        
      >
        <div className="image-container" style={{ textAlign: 'center', marginBottom: '20px' }}>
        {/* <img
          src="http://betolopes.com.br/img/logo-agenda-vert-bc-sf.png"
          alt="Logo"
          style={{ maxWidth: '50%', height: 'auto' }}
        /> */}
      </div>
        <div className="login-container">
          <h2>Login</h2>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <form onSubmit={handleLogin}>
            <div className="input-group">
              <input
                type="text"
                placeholder="Usuário"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="input-group">
              <input
                type="password"
                placeholder="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit" className="btn-login">
              Entrar
            </button>
            
            <p className="small-text-login">
          Não tem uma conta? <Link to="/register">Cadastrar</Link>
        </p>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
