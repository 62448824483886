import React from 'react';

function About() {
  return (
    <div>
      <h1>Sobre a Empresa</h1>
      <p>Informações sobre a empresa Beto Lopes.</p>
    </div>
  );
}

export default About;
