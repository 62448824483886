import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <section className="hero">
      <div className="hero-content">
        <h2>Beto Lopes</h2>
        <h2>Bem-vindo à nossa empresa!</h2>
        <p>Desenvolvimento, inovação e soluções digitais.</p>
        <Link to="/login" className="btn-hero">Acesse sua conta</Link>

        <p className="small-text">
          Não tem uma conta? <Link to="/register">Cadastrar</Link>
        </p>
      </div>
    </section>
  );
}

export default Home;
