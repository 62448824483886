import React from 'react';

function Services() {
  return (
    <div>
      <h1>Nossos serviços</h1>
      <p>Informações sobre a os serviços da empresa Beto Lopes.</p>
    </div>
  );
}

export default Services;
