import React from 'react';

function Contact() {
  return (
    <div>
      <h1>Fale conosco</h1>
      <p>Contatos da empresa.</p>
    </div>
  );
}

export default Contact;
