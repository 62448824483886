import React, { useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation } from 'react-router-dom';

import '../App.css';

function Dashboard() {
  const location = useLocation();
  const [compromissos, setCompromissos] = useState([]);
  const [mesSelecionado, setMesSelecionado] = useState(format(new Date(), 'MMM yy'));
  const [compromissosFiltrados, setCompromissosFiltrados] = useState([]);
  const [modalVisivel, setModalVisivel] = useState(false);
  const [mostrarDatePicker, setMostrarDatePicker] = useState(false);
  const inputRef = useRef(null);

  const [form, setForm] = useState({
    id: null,
    descricao: '',
    vencimento: '',
    valor: '',
    tipo: 'Débito',
    status: '',
  });
  const { user, compromissos: compromissosNavegados } = location.state || {};

  const safeFormat = (date, formatStr) => {
    if (!date || isNaN(new Date(date).getTime())) return 'Data inválida';
    return format(new Date(date), formatStr);
  };

  useEffect(() => {
    if (compromissosNavegados) {
      setCompromissos(compromissosNavegados);
    } else {
      const storedCompromissos = JSON.parse(localStorage.getItem('compromissos')) || [];
      setCompromissos(storedCompromissos);
    }
  }, [compromissosNavegados]);

  useEffect(() => {
    const filtrarCompromissos = () => {
      const filtrados = compromissos
        .filter((item) => {
          if (!item.vencimento || isNaN(new Date(item.vencimento).getTime())) {
            console.warn('Data de vencimento inválida:', item.vencimento);
            return false;
          }
          const mesItem = safeFormat(item.vencimento, 'MMM yy');
          return mesItem === mesSelecionado;
        })
        .sort((a, b) => new Date(a.vencimento) - new Date(b.vencimento));
      setCompromissosFiltrados(filtrados);
    };
    filtrarCompromissos();
  }, [mesSelecionado, compromissos]);

  const handleSubmit = async () => {
    if (!form.descricao || !form.vencimento || !form.valor || !form.tipo || !form.status) {
      alert('Preencha todos os campos!');
      return;
    }

    try {
      const url = form.id
        ? 'http://betolopes.com.br/web/compromissos_edit.php'
        : 'http://betolopes.com.br/web/compromissos_reg.php';

      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user, form }),
      });

      if (response.ok) {
        alert('Compromisso salvo com sucesso!');
        const atualizado = await response.json();
        setCompromissos(atualizado.compromissos);
        const filtrados = atualizado.compromissos.filter((item) => {
          const mesItem = safeFormat(item.vencimento, 'MMM yy');
          return mesItem === mesSelecionado;
        });
        setCompromissosFiltrados(filtrados);
        limparForm();
      } else {
        alert('Erro ao salvar o compromisso.');
      }
    } catch (error) {
      alert('Erro ao salvar compromisso:', error);
    }
  };

  const handleExcluir = async (id) => {
    if (!window.confirm('Deseja realmente excluir este compromisso?')) return;

    try {
      const response = await fetch('http://betolopes.com.br/web/compromissos_delete.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      });

      if (response.ok) {
        alert('Compromisso excluído com sucesso!');
        setCompromissos((prev) => prev.filter((item) => item.id !== id));
      } else {
        alert('Erro ao excluir o compromisso.');
      }
    } catch (error) {
      alert('Erro ao excluir compromisso:', error);
    }
  };

  const handleEditar = (item) => {
    setForm(item);
    setModalVisivel(true);
  };

  const limparForm = () => {
    setForm({
      id: null,
      descricao: '',
      vencimento: '',
      valor: '',
      tipo: 'Débito',
      status: '',
    });
    setModalVisivel(false);
  };

  return (
    <div style={{ backgroundColor: '#0056b3', minHeight: '100vh'  }}>
      <div
        style={{
          backgroundColor: '#0056b3',
          padding: '10px 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <img
          src="http://betolopes.com.br/img/logo-agenda-lateral-bc-sf.png"
          alt="Logo"
          style={{ maxWidth: '15%', height: 'auto' }}
        />
      </div>

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <button
          onClick={() => setModalVisivel(true)}
          style={{ marginRight: '10px', padding: '8px 16px', borderRadius: '4px', backgroundColor: '#fff' }}
        >
          Adicionar Compromisso
        </button>
        <span style={{ marginLeft: '10px' }}>
          Mês selecionado:{' '}
          <input
            type="text"
            value={mesSelecionado}
            readOnly
            onClick={() => setMostrarDatePicker(true)}
            ref={inputRef}
            style={{
              cursor: 'pointer',
              textAlign: 'center',
              height: '25px',
              width: '70px',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          />
        </span>
        {mostrarDatePicker && (
          <DatePicker
            selected={new Date()}
            onChange={(date) => {
              setMesSelecionado(format(date, 'MMM yy'));
              setMostrarDatePicker(false);
            }}
            dateFormat="MMM yy"
            showMonthYearPicker
            inline
          />
        )}
      </div>

      <div
        id="compromissos"
        style={{
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        {compromissosFiltrados.length > 0 ? (
          compromissosFiltrados.map((item) => (
            <div
              key={item.id}
              style={{
                backgroundColor: '#f5ffff',
                border: '1px solid #ccc',
                padding: '10px',
                marginBottom: '10px',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>{item.descricao}</h5>
                <div>
                  <button
                    onClick={() => handleEditar(item)}
                    style={{ marginRight: '10px', backgroundColor: '#4caf50', color: 'white' }}
                  >
                    Editar
                  </button>
                  <button
                    onClick={() => handleExcluir(item.id)}
                    style={{ color: 'red', backgroundColor: '#fff', border: '1px solid red' }}
                  >
                    Excluir
                  </button>
                </div>
              </div>

              <p>
                Vencimento: {item.vencimento ? format(new Date(item.vencimento), 'dd/MM') : 'Data inválida'}
              </p>
              <p>Valor: R$ {item.valor}</p>
            </div>
          ))
        ) : (
          <p>Nenhum compromisso para este mês.</p>
        )}
      </div>

      {modalVisivel && (
        <div className="modal-overlay">
          <div className="modal-container">
            <h3>{form.id ? 'Editar Compromisso' : 'Adicionar Compromisso'}</h3>
            <input
              type="text"
              placeholder="Descrição"
              value={form.descricao}
              onChange={(e) => setForm({ ...form, descricao: e.target.value })}
              style={{ display: 'block', margin: '10px 0' }}
            />
            <input
              type="date"
              value={form.vencimento}
              onChange={(e) => setForm({ ...form, vencimento: e.target.value })}
              style={{ display: 'block', margin: '10px 0' }}
            />
            <input
              type="number"
              placeholder="Valor"
              value={form.valor}
              onChange={(e) => setForm({ ...form, valor: e.target.value })}
              style={{ display: 'block', margin: '10px 0' }}
            />
            <select
              value={form.tipo}
              onChange={(e) => setForm({ ...form, tipo: e.target.value })}
              style={{ display: 'block', margin: '10px 0' }}
            >
              <option value="Débito">Débito</option>
              <option value="Crédito">Crédito</option>
            </select>
            <select
              value={form.status}
              onChange={(e) => setForm({ ...form, status: e.target.value })}
              style={{ display: 'block', margin: '10px 0' }}
            >
              <option value="Pendente">Pendente</option>
              <option value="Pago">Pago</option>
            </select>
            <button
              onClick={handleSubmit}
              style={{
                marginRight: '10px',
                backgroundColor: '#4caf50',
                color: 'white',
                padding: '8px 16px',
                borderRadius: '4px',
              }}
            >
              Salvar
            </button>
            <button onClick={() => limparForm()} style={{ backgroundColor: '#f44336', color: 'white' }}>
              Cancelar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
